
<template>
    <div>
        <b-modal id="modal-edit-bv-hot-sport-match" cancel-variant="outline-secondary" :ok-title="$t('Edit')"
            :cancel-title="$t('Close')" ok-variant="success" centered no-enforce-focus modal-class="modal-success"
            :title="$t('Edit Hot Sport Match')" @ok="confirmAdd" @hidden="resetModal()">
            <!-- form -->
            <validation-observer ref="addSportTeamModal">
                <b-form method="POST" @submit.prevent="validationForm">
                    <b-form-group label-for="league_title">
                        <label class="mb-1">League title</label>
                        <b-form-input disabled id="league_title" v-model="search.league_title" class="form-control"/>
                    </b-form-group>
                    <b-form-group label-for="localteam_title">
                        <label class="mb-1">Local Team title</label>
                        <b-form-input disabled id="localteam_title" v-model="search.localteam_title" class="form-control"/>
                    </b-form-group>
                    <b-form-group label-for="visitorteam_title">
                        <label class="mb-1">Visitor team title</label>
                        <b-form-input disabled id="visitorteam_title" v-model="search.visitorteam_title" class="form-control"/>
                    </b-form-group>
                    <b-form-group label-for="param_extensions">
                        <label class="mb-1">Param Extension</label>
                        <validation-provider #default="{ errors }" name="param_extensions">
                            <b-form-input id="param_extensions" v-model="search.param_extensions" class="form-control"
                                placeholder="Please Param Extension" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="link_live_stream">
                        <label class="mb-1">Link live stream</label>
                        <validation-provider #default="{ errors }" name="link_live_stream">
                            <b-form-input id="link_live_stream" v-model="search.link_live_stream" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="hide">
                        <label class="mb-1">Hide</label>
                        <validation-provider #default="{ errors }" name="hide" rules="required">
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="search.hide"
                                :options="hideOptions" class="w-100" :reduce="(val) => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="hot">
                        <label class="mb-1">Hot</label>
                        <validation-provider #default="{ errors }" name="hot" rules="required">
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="search.hot"
                                :options="hotOptions" class="w-100" :reduce="(val) => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
  
<script>
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import router from "@/router";
export default {
    components: {
        BLink,
        BRow,
        BCol,
        BTabs,
        BTab,
        BForm,
        BFormGroup,
        BFormInput,
        BFormFile,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BModal,
        BFormTextarea,
        BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        flatPickr
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        bvHotSportMatchDetail: {
            type: Object,
            default: null,
        },
        hideOptions: {
            type: Array,
            default: null,
        },
        hotOptions: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            search: {
                hide: "",
                hot: "",
                param_extensions: "",
                link_live_stream: "",
                league_title: "",
                localteam_title: "",
                visitorteam_title: "",
            },
        };
    },
    watch: {
        bvHotSportMatchDetail: {
            handler(newVal) {
                if (newVal) {
                    this.search = {
                        id: newVal.id,
                        hide: newVal.hide,
                        hot: newVal.hot,
                        param_extensions: newVal.param_extensions,
                        link_live_stream: newVal.link_live_stream,
                        league_title: newVal.league_title,
                        localteam_title: newVal.localteam_title,
                        visitorteam_title: newVal.visitorteam_title,
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        validationForm() {
            this.$refs.addSportTeamModal.validate().then((success) => {
                if (success) {
                    this.editSportHotMatch(this.search);
                }
            });
        },
        async editSportHotMatch(sportTeamData) {
            await this.$store
                .dispatch("sports/editBvSportHotMatch", {
                    id: sportTeamData.id,
                    hide: sportTeamData.hide,
                    param_extensions: sportTeamData.param_extensions,
                    hot: sportTeamData.hot,
                    link_live_stream: sportTeamData.link_live_stream
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.code == "00") {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "success",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide("modal-edit-bv-hot-sport-match");
                                    this.resetModal();
                                }, "500");
                            });
                            this.$emit("refetch-data");
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                });
        },
        resetModal() {
            this.search = {
                id: "",
                hot: "",
                hide: "",
                link_live_stream: "",
                param_extensions: "",
                league_title: "",
                localteam_title: "",
                visitorteam_title: "",
            };
            this.$emit("update:bvHotSportMatchDetail", null);
        },
        confirmAdd(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.validationForm();
        },
    },
    setup() {
        const formatDate = {
            enableTime: true,
            dateFormat: "Y-m-d H:i:s",
        };
        return {
            formatDate
        };
    },
};
</script>
  
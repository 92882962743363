<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="3" class="mb-2">
                        <b-form-group label="Match ID" label-for="name" class="mb-0">
                            <b-form-input
                                id="name"
                                v-model="search.match_id"
                                class="form-control"
                            />
                            </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <b-form-group label="League title" label-for="name" class="mb-0">
                            <b-form-input
                                id="name"
                                v-model="search.league_title"
                                class="form-control"
                            />
                            </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <b-form-group label="Localteam title" label-for="name" class="mb-0">
                            <b-form-input
                                id="name"
                                v-model="search.localteam_title"
                                class="form-control"
                            />
                            </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <b-form-group label="Visitorteam title" label-for="name" class="mb-0">
                            <b-form-input
                                id="name"
                                v-model="search.visitorteam_title"
                                class="form-control"
                            />
                            </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Hide</label>
                        <v-select v-model="search.hide" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="hideOptions" :reduce="(val) => val.value" :clearable="true"
                            input-id="hide" />
                    </b-col>

                    <b-col cols="12" md="3" class="mb-2">
                        <label>Hot</label>
                        <v-select v-model="search.hot" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="hotOptions" :reduce="(val) => val.value" :clearable="true"
                            input-id="hot" />
                    </b-col>

                    <b-col cols="12" md="3" class="d-flex align-items-end justify-content-end">
                        <b-button variant="outline-primary" @click="resetFilter()">
                            Reset
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="handleSearch()">
                            Search
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-card no-body>
            <div v-if="loading" class="d-flex justify-content-center mb-3">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-else>
                <b-table ref="refSportMatchListTable" class="position-relative table-white-space mb-0 max-height-table"
                    sticky-header head-variant="light" :items="sportMatchList" responsive :fields="tableColumns"
                    primary-key="index" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                    :sort-desc.sync="isSortDirDesc" busy.sync="true" @row-clicked=onRowClicked>

                    <div slot="table-busy" class="text-center mt-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <template #cell(index)="data">
                        {{ perPage * (currentPage - 1) + data.index + 1 }}
                    </template>

                    <template #cell(visitorteam_logo)="data">
                        <b-link :href="data.item.visitorteam_logo" target="_blank" class="cursor-pointer"
                            v-if="data.item.visitorteam_logo != null">
                            <b-img :src="data.item.visitorteam_logo" fluid class="mr-2 rounded"
                                style="width: 100px; height: 100px;" />
                        </b-link>
                        <b-link v-else> </b-link>
                    </template>

                    <template #cell(localteam_logo)="data">
                        <b-link :href="data.item.localteam_logo" target="_blank" class="cursor-pointer"
                            v-if="data.item.localteam_logo != null">
                            <b-img :src="data.item.localteam_logo" fluid class="mr-2 rounded"
                                style="width: 100px; height: 100px;" />
                        </b-link>
                        <b-link v-else> </b-link>
                    </template>

                    <template #cell(hot)="data">
                        {{ data.item.hot == 1 ? 'Hot' : 'Không hot' }}
                    </template>

                    <template #cell(hide)="data">
                        {{ data.item.hide == 1 ? 'Hiện' : 'Ẩn' }}
                    </template>

                    <template #cell(created_at)="data">
                        <div v-if="data.item.created_at !== null">
                            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
                        </div>
                    </template>

                    <template #cell(updated_at)="data">
                        <div v-if="data.item.updated_at !== null">
                            {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <span v-if="data.item.status === 1" class="text-success">
                            Active
                        </span>
                        <span v-if="data.item.status === 2" class="text-warning">
                            Inactive
                        </span>
                    </template>

                    <template #cell(action)="data">
                        <b-button v-if="$can('update', 'sportmatch')" v-b-tooltip.hover.v-info :title="$t('Edit')"
                            variant="primary" class="btn-icon btn-sm mr-50" @click="showEditSportTeamModal(data.item)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </template>

                </b-table>
                <div class="p-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                                {{ dataMeta.of }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
                            <b-pagination v-model="currentPage" :total-rows="totalSportMatch" :per-page="perPage"
                                first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                next-class="next-item">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <edit-bv-hot-sport-match-modal :bv-hot-sport-match-detail.sync="bvHotSportMatchDetail"
            :hot-options.sync="hotOptions" :hide-options.sync="hideOptions" @refetch-data="refetchData" />
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    VBTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg, BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import sportsStoreModule from "@/views/sports/sportsStoreModule";
import EditBvHotSportMatchModal from "@/views/sports/EditBvHotSportMatchModal.vue";
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        EditBvHotSportMatchModal,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        vSelect,
        BImg,
        flatPickr
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            bvHotSportMatchDetail: null,
        };
    },
    methods: {
        showEditSportTeamModal(datas) {
            this.bvHotSportMatchDetail = datas;
            this.$bvModal.show("modal-edit-bv-hot-sport-match");
        },
        onRowClicked(item, index, event) {
            this.showEditSportTeamModal(item)
        }
    },
    setup() {
        if (!store.hasModule("sports"))
            store.registerModule("sports", sportsStoreModule);
        onUnmounted(() => {
            if (store.hasModule("sports")) store.unregisterModule("sports");
        });

        const toast = useToast();

        const search = ref({
            match_id: "",
            visitorteam_title: "",
            league_title: "",
            localteam_title: "",
            hide: "",
            hot: ""
        });
        const formatDate = {
            enableTime: true,
            dateFormat: "Y-m-d H:i:s",
        };
        const sportMatchList = ref([]);
        const sportTypeOption = ref([]);
        const sportLeaguesOption = ref([]);
        const sportTeamOption = ref([]);
        const sportTypeIdFilter = ref(null);
        const nameFilter = ref(null);
        const loading = ref(false);

        const perPage = ref(25);
        const totalSportMatch = ref(0);
        const currentPage = ref(1);
        const perPageOptions = [10, 25, 50, 100];
        const sortBy = ref("id");
        const isSortDirDesc = ref(true);

        const refSportMatchListTable = ref(null);

        const tableColumns = [
            { key: "index", label: "#" },
            { key: "match_id", label: "Match ID" },
            { key: "league_title", label: "League Title" },
            { key: "league_short_name", label: "League Short Name" },
            { key: "localteam_id", label: "localteam_id" },
            { key: "localteam_logo", label: "localteam_logo" },
            { key: "localteam_title", label: "localteam_title" },
            { key: "visitorteam_id", label: "visitorteam_id" },
            { key: "visitorteam_logo", label: "visitorteam_logo" },
            { key: "visitorteam_title", label: "visitorteam_title" },
            { key: "link_keo", label: "link_keo" },
            { key: "link_live_stream", label: "link_live_stream" },
            { key: "links", label: "links" },
            { key: "links1", label: "links1" },
            { key: "match_id", label: "match_id" },
            { key: "muagiai", label: "muagiai" },
            { key: "param_extensions", label: "param_extensions" },
            { key: "sanvandong", label: "sanvandong" },
            { key: "vongdau", label: "vongdau" },
            { key: "hide", label: "hide" },
            { key: "hot", label: "hot" },
            { key: "score", label: "score" },
            { key: "start_time", label: "start_time" },
            { key: "end_time", label: "end_time" },
            "created_at",
            "updated_at",
            { key: "action", label: "Action" },
        ];

        const refetchData = () => {
            getSportMatchList();
        };

        watch([currentPage], () => {
            refetchData();
        });

        const getSportMatchList = (ctx, callback) => {
            loading.value = true;
            store.dispatch("sports/fetchBvSportHotMatch", {
                match_id: search.value.match_id,
                hide: search.value.hide,
                hot: search.value.hot,
                league_title: search.value.league_title,
                localteam_title: search.value.localteam_title,
                visitorteam_title: search.value.visitorteam_title,
                pageNumber: currentPage.value,
            }).then((response) => {
                if (response.data.code == "00") {
                    sportMatchList.value = response.data.data.map((item, index) => {
                        return { ...item, index: index }
                    });
                    totalSportMatch.value = response.data.count;
                    loading.value = false;
                } else {
                    loading.value = false;
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                }
            });
        };

        const dataMeta = computed(() => {
            const localItemsCount = refSportMatchListTable.value
                ? refSportMatchListTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalSportMatch.value,
            };
        });

        const statusOptions = [
            { label: "Active", value: 1 },
            { label: "Inactive", value: 2 },
        ];

        const hideOptions = [
            { label: "Ẩn", value: '2' },
            { label: "Hiện", value: '1' },
        ];

        const hotOptions = [
            { label: "Hot", value: 1 },
            { label: "Không hot", value: 2 },
        ];

        const resolveStatus = (status) => {
            if (status == 1) return { label: "Active", variant: "primary" };
            if (status == 2) return { label: "Inactive", variant: "danger" };
            return { label: "Pending", variant: "secondary" };
        };

        const resetFilter = () => {
            search.value.match_id = '';
            search.value.hide = '';
            search.value.hot = '';
            search.value.league_title = '';
            search.value.localteam_title = '';
            search.value.visitorteam_title = '';
            handleSearch();
        };

        getSportMatchList();

        const handleSearch = () => {
            getSportMatchList();
        }

        return {
            tableColumns,
            perPage,
            currentPage,
            totalSportMatch,
            dataMeta,
            perPageOptions,
            sortBy,
            isSortDirDesc,
            refSportMatchListTable,

            sportMatchList,
            refetchData,
            nameFilter,
            search,

            resetFilter,
            sportTypeIdFilter,

            resolveStatus,
            statusOptions,

            formatDateTime,
            handleSearch,
            baseUrl,
            formatDate,
            loading,
            hideOptions,
            hotOptions
        };
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
